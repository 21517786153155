import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

export function useShipperNetworkGroup(groupId: number) {
  const { role } = useRoleContext();
  const [networkGroup, setNetworkGroup] = useState<NetworkGroup | null>(null);
  const {
    fetch, loading, error, success, resetState,
  } = useFetchingComponentState<NetworkGroup>({
    errorMessage: 'Failed to fetch network group',
    fetchConfig: {
      url: `/network-groups/${groupId}`,
    },
  });

  useEffect(() => {
    if (role != null && role.type === 'SHIPPER' && !success) {
      fetch((res) => {
        setNetworkGroup(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    networkGroup,
    loadingNetworkGroup: loading,
    networkGroupError: error,
    refetchNetworkGroup: resetState,
    networkGroupFetchSuccess: success,
  };
}
