import { Button, Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useDisclosure, useShipperNetworkGroups } from 'hooks';
import { useState } from 'react';
import { CreateNetworkGroupModal } from './components/CreateNetworkGroupModal';
import { NetworkGroupDetailModal } from './components/NetworkGroupDetailModal';
import { NetworkGroupOverview } from './components/NetworkGroupOverview';

export function NetworkGroupManager() {
  const {
    networkGroups,
    loadingNetworkGroups,
    networkGroupsError,
    refetchNetworkGroups,
  } = useShipperNetworkGroups();

  const {
    isOpen: manageGroupOpen,
    onClose: onCloseManageGroup,
    onOpen: onOpenManageGroup,
  } = useDisclosure(false);

  const {
    isOpen: createGroupOpen,
    onClose: onCloseCreateGroup,
    onOpen: onOpenCreateGroup,
  } = useDisclosure(false);

  const [selectedGroup, setSelectedGroup] = useState<NetworkGroup | null>(null);

  const onManageGroupSelected = (branch: NetworkGroup) => {
    setSelectedGroup(branch);
    onOpenManageGroup();
  };

  return (
    <Stack direction="column" mt="1rem">
      <Stack direction="row" alignItems="center">
        <Typography variant="h5">Network Groups</Typography>
        <RefreshPageButton id="refresh-branches" onRefresh={refetchNetworkGroups} iconButton />
      </Stack>
      <EvotruxDivider />
      <div>
        <Button sx={{ mt: '.5rem' }} variant="contained" onClick={onOpenCreateGroup}>Create Group</Button>
      </div>
      <LoadingWrappedNode loading={loadingNetworkGroups}>
        <Stack mt=".5rem" direction="row" spacing={1.5} justifyContent="start" flexWrap="wrap">
          {networkGroups.map((group) => (
            <NetworkGroupOverview
              key={group.group.id}
              group={group}
              onManageGroup={onManageGroupSelected}
            />
          ))}
        </Stack>
      </LoadingWrappedNode>
      <ErrorTypography error={networkGroupsError} />
      <NetworkGroupDetailModal
        isOpen={manageGroupOpen}
        onClose={onCloseManageGroup}
        onRefreshNetworkGroups={refetchNetworkGroups}
        group={selectedGroup}
      />
      <CreateNetworkGroupModal
        isOpen={createGroupOpen}
        onClose={onCloseCreateGroup}
        onRefreshNetworkGroups={refetchNetworkGroups}
      />
    </Stack>
  );
}
