import Checkbox from '@mui/material/Checkbox';

interface Props {
  onChange : (value: boolean) => void;
  value: boolean;
}

export function IncludeBranchShipmentsCheckbox(props: Props) {
  const { onChange, value } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <>
      <p>All Shipments</p>
      <Checkbox
        checked={value}
        onChange={handleChange}
      />
    </>
  );
}
