/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig } from 'axios';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  clearClientData, extendSessionTTL, getLocalAuth,
  getLocalRefreshToken,
  getLocalUser, localStorageDataIsStale, setUser,
  storeSessionInitData,
} from '../../clientData/clientDataRepo';
import { authContext } from '../../context';

export function useAuthContext() {
  const userCtx = React.useContext(authContext);
  const navigate = useNavigate();

  const signout = React.useCallback(() => {
    navigate('/');
    clearClientData();
    window.location.reload();
  }, [navigate]);

  // update to only proceed if TTL makes sense
  const refreshTokens = React.useCallback(() => {
    const localRefreshToken = getLocalRefreshToken();
    const localUser = getLocalUser();

    if (localRefreshToken === null || localUser === null || localStorageDataIsStale()) {
      signout();
      return;
    }

    axios.patch(
      `${process.env.REACT_APP_API_URL}/tokens/refresh`,
      {
        email: localUser.email,
        refresh_token: localRefreshToken,
      },
    ).then((res) => {
      const { data: { data } } = res;
      // no refreshToken is sent back on response: refreshing a token provides access token,
      // not a refresh token
      userCtx.dispatch({ type: 'refreshToken', data: { auth: data.auth, user: localUser, pubnub: data.pubnub } });
      extendSessionTTL();
      navigate(window.location.pathname === '/login' ? '/' : window.location.pathname);
    }).catch(() => {
    // refresh token has likely expired
      signout();
    });
  }, [userCtx, navigate, signout]);

  function identifyUserInSentry(user: User) {
    Sentry.setUser({ email: user.email, id: user.id });
  }

  function onLoginSuccess(user: User, auth: SignInAuthData, pubnub: PubnubClientData) {
    userCtx.dispatch({ type: 'login', data: { user, auth, pubnub } });
    storeSessionInitData(user, auth);
    identifyUserInSentry(user);
    navigate(window.location.pathname === '/login' ? '/' : window.location.pathname);
  }

  function handleLoginError(errorStatus: number, message? : string) {
    let loginError = 'There was an error while signing in. Please contact us if the issue persists.';
    if (message) {
      loginError = message;
    } else if (errorStatus === 401) {
      loginError = 'Failed to log in. Please contact us.';
    }
    userCtx.dispatch({ type: 'loginError', data: { loginErrorMsg: loginError } });
  }

  const refresh = React.useCallback(() => {
    const localUser = getLocalUser();
    const localRefreshToken = getLocalRefreshToken();
    if (localUser != null && localRefreshToken != null) {
      refreshTokens();
    } else {
      signout();
    }
  }, [refreshTokens, signout]);

  const login = async (
    email: string,
    password: string,
    voluntellLogin = false,
    shipmentId: string | null = null,
  ) => {
    userCtx.dispatch({ type: 'requestLogin', data: { voluntellLogin } });
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      email,
      password,
    }).then((res) => {
      const { data: { data } } = res;
      if (data.auth.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        userCtx.dispatch({
          type: 'changePasswordChallenge',
          data: {
            user: data.user,
            challengeAuth: data.auth,
            isVoluntell: voluntellLogin,
            pubnub: data.pubnub,
          },
        });
        if (voluntellLogin) {
          if (shipmentId) {
            navigate(`/force-change-password?activate=1&shipment=${shipmentId}`);
          } else {
            navigate('/force-change-password?activate=1');
          }
        } else {
          navigate('/force-change-password');
        }
      } else {
        onLoginSuccess(data.user, data.auth, data.pubnub);
      }
    }).catch(
      (e) => {
        handleLoginError(e?.response?.status, e?.response?.data?.message);
      },
    );
  };

  const refreshChatTokens = React.useCallback((onRefreshSuccess?: () => void) => {
    if (userCtx.user && userCtx.auth?.AuthenticationResult.IdToken) {
      axios.get(
        `${process.env.REACT_APP_API_URL}/user/chat-session`,
        { headers: { Authorization: `Bearer ${userCtx.auth?.AuthenticationResult.IdToken}` } },
      ).then((res) => {
        userCtx.dispatch({ type: 'updatePubnubData', data: { pubnub: res.data.pubnub } });
      }).then(() => {
        if (onRefreshSuccess) {
          onRefreshSuccess();
        }
      });
    }
    return null;
  }, [userCtx]);

  async function forceChangePassword(
    email: string,
    newPassword: string,
    session: string,
    shipmentId: string | null = null,
  ) {
    return axios.post(`${process.env.REACT_APP_API_URL}/challenge/change-password`, {
      email,
      new_password: newPassword,
      session,
    }).then((res) => {
      const { data: { data } } = res;
      userCtx.dispatch({ type: 'login', data: { user: data.user, auth: data.auth, pubnub: data.pubnub } });
      storeSessionInitData(data.user, data.auth);
      if (shipmentId) {
        navigate(`/shipments/${shipmentId}?focus=quotes`);
      } else {
        navigate('/');
      }
    }).catch(
      (e) => {
        handleLoginError(e?.response?.status);
      },
    );
  }

  const setLoginError = (errorMsg: string) => {
    userCtx.dispatch({ type: 'loginError', data: { loginErrorMsg: errorMsg } });
  };

  const refreshUser = React.useCallback(async () => {
    const { auth, dispatch } = userCtx;
    let idToken = null;
    if (auth != null) {
      idToken = auth.AuthenticationResult.IdToken;
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/user`,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      return axios.request(config).then((res) => {
        setUser(res.data as User);
        dispatch({ type: 'setUser', data: { user: res.data } });
      }).catch(() => { signout(); });
    }

    return signout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.auth, userCtx.dispatch]);

  async function signup(params: SignUpPayload, password: string, role: RoleValue) {
    const {
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      first_name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      last_name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      recatpcha_token,
    } = params;

    return axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
      email,
      password,
      first_name,
      last_name,
      recatpcha_token,
      role,
    }).then((res) => {
      const { data: { data } } = res;
      userCtx.dispatch({ type: 'setUser', data: { user: data.user } });
    });
  }

  React.useEffect(() => {
    const localStorageAuth = getLocalAuth();
    const localStorageUser = getLocalUser();

    if (
      userCtx.auth == null
      && userCtx.user == null
      && localStorageAuth != null
      && localStorageUser != null
      && !userCtx.isInitialized
    ) {
      /*
        if local storage contains all the info we need, and
        the auth context is not initialized, then
        refresh the access token and the user data.
        (So that auth data can be loaded in memory/into the auth context).
        This hook runs on every page load that actually refreshes the page
        (such as a window.location.reload();)
      */
      refreshTokens();
    } else
    if ((localStorageAuth == null || localStorageUser == null)
    && userCtx.isLoading) {
      // local storage does not contain the info required to refresh session: sign out
      userCtx.dispatch({ type: 'requireAuth' });
    }
  }, [userCtx, refreshTokens, signout]);

  let idToken = null;
  if (userCtx.auth != null) {
    idToken = userCtx.auth.AuthenticationResult.IdToken;
  }

  return {
    user: userCtx.user,
    isAuthenticated: userCtx.isAuthenticated,
    challenge: userCtx.challengeAuth,
    isLoading: userCtx.isLoading,
    loginErrorMsg: userCtx.loginError,
    attemptedVoluntellLogin: userCtx.attemptedVoluntellLogin,
    login,
    signup,
    signout,
    refreshTokens: refresh,
    refreshUser,
    setLoginError,
    forceChangePassword,
    idToken,
    pubnub: userCtx.pubnub,
    refreshChatTokens,
  };
}
