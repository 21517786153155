import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { NetworkGroupMemberTableRow } from './NetworkGroupMemberTableRow';

interface Props {
  members: NetworkGroupMember[];
  onRefresh: VoidFunction;
}

export function NetworkGroupMemberTable(props: Props) {
  const { members, onRefresh } = props;
  return (
    <Paper sx={{ mt: '1rem', width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Added On</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member: NetworkGroupMember) => (
              <NetworkGroupMemberTableRow
                key={member.id}
                member={member}
                onRefresh={onRefresh}
              />

            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
