import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

export function useShipperNetwork() {
  const { role } = useRoleContext();
  const [followingCarriers, setFollowingCarriers] = useState<FollowingCarrier[]>([]);
  const {
    fetch, loading, error, success, resetState,
  } = useFetchingComponentState<FollowingCarrier[]>({
    errorMessage: 'Failed to fetch shipper network',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/followers` : '/',
    },
  });

  useEffect(() => {
    if (role != null && role.type === 'SHIPPER' && !success) {
      fetch((res) => {
        setFollowingCarriers(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    followingCarriers,
    loading,
    error,
    refetch: resetState,
    success,
  };
}
