import {
  TextField,
} from '@mui/material';
import { useInterval, useShipmentFiltersContext } from 'hooks';
import { useCallback, useState } from 'react';

export function ShipmentSearchByName() {
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastValueSearched, setLastValueSearched] = useState<string | null>(null);
  const {
    updateShipmentName,
    filters,
  } = useShipmentFiltersContext();

  const search = useCallback(() => {
    if (searchValue === '' && filters?.shipmentName !== '') {
      updateShipmentName(searchValue);
    } else if (lastValueSearched !== searchValue) {
      updateShipmentName(searchValue);
      setLastValueSearched(searchValue);
    }
  }, [searchValue,
    lastValueSearched,
    setLastValueSearched,
    updateShipmentName, filters?.shipmentName]);

  useInterval(search, 1500);

  return (
    <TextField
      onChange={(e) => setSearchValue(e.target.value)}
      label="Shipment Ref/ID"
      value={searchValue}
      sx={{ width: '100%' }}
    />
  );
}
