import {
  Button,
  Chip, Stack, TableCell, TableRow, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useWriterComponentState } from 'hooks';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  pendingRequest: FollowShipperRequestAndResponse,
  onRefetchFollowers: VoidFunction;
}

export function ShipperPendingRequestTableRow(props: Props) {
  const { pendingRequest, onRefetchFollowers } = props;

  const {
    write, error, loading,
  } = useWriterComponentState({
    errorMessage: 'Failed to delete request. Please contact us if the problem persists.',
    writeConfig: {
      method: 'delete',
      url: `/follow_requests/${pendingRequest.request.id}`,
    },
  });

  const onDeleteRequest = () => {
    write(() => {
      onRefetchFollowers();
    });
  };

  return (
    <TableRow
      key={pendingRequest.request.id}
    >
      <TableCell>
        {pendingRequest.request.type === 'SHIPPER_FOLLOW_REQUEST' ? (
          <Typography variant="body1">
            {pendingRequest.request.target_role.user.first_name}
            {' '}
            {pendingRequest.request.target_role.user.last_name}
          </Typography>
        ) : (
          <Typography variant="body1">
            {pendingRequest.request.target_email}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1">{ISOStringToLocalReadableTimestamp(pendingRequest.request.created_at)}</Typography>
      </TableCell>
      <TableCell>
        <Chip color="warning" label="Pending" />
      </TableCell>
      <TableCell>
        <Stack>
          <LoadingWrappedNode loading={loading}>
            <Button variant="outlined" color="info" onClick={onDeleteRequest}>Delete Request</Button>
            <ErrorTypography error={error} />
          </LoadingWrappedNode>
        </Stack>

      </TableCell>
    </TableRow>
  );
}
