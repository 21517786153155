import {
  CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ShipperUpgradeNowButton } from 'components/Shipper/components/ShipperUpgradeNowButton';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { PrivateShipmentPartnerSelect } from './components/PrivateShipmentPartnerSelect';
import { NetworkMember } from './types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  confirmedNetworkMembers: NetworkMember[];
  networkMembers: NetworkMember[];
  activeExternalFetchError: string | null;
  carrierNeworkFetchError: string | null;
  loadingNetworkMembers: boolean;
  loadingActiveExternalInvites: boolean;
  onNetworkNotificationTypeChanged: (value: 'ALL_NETWORK' | 'SELECTED_CARRIERS') => void;
  onShipmentVisibilityChanged: (value: 'PUBLIC' | 'PRIVATE') => void;
  setShipmentNotificationEmails: (emails: string[]) => void;
  shipmentVisibility: 'PUBLIC' | 'PRIVATE';
  networkNotification: 'ALL_NETWORK' | 'SELECTED_CARRIERS';
  selectedCarriers: string[];
  isProject: boolean;
  groupSelectComponent: React.ReactNode;
}

export function ShipmentNotificationsSelectMenu(props: Props) {
  const {
    networkMembers,
    activeExternalFetchError,
    carrierNeworkFetchError,
    loadingNetworkMembers,
    loadingActiveExternalInvites,
    setShipmentNotificationEmails,
    onNetworkNotificationTypeChanged,
    onShipmentVisibilityChanged,
    shipmentVisibility,
    networkNotification,
    confirmedNetworkMembers,
    selectedCarriers,
    isProject,
    groupSelectComponent,
  } = props;

  const handleNetworkNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNetworkNotificationTypeChanged((event.target as HTMLInputElement).value as
      'ALL_NETWORK' | 'SELECTED_CARRIERS');
  };
  const handleShipmentVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onShipmentVisibilityChanged((event.target as HTMLInputElement).value as 'PRIVATE' | 'PUBLIC');
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedCarriers>) => {
    const {
      target: { value },
    } = event;
    setShipmentNotificationEmails(typeof value === 'string' ? value.split(',') : value);
  };
  const { subscription } = useSubscriptionStatus();
  if (subscription == null) {
    return <div />;
  }

  const getSelectPartnersLabel = () => {
    if (shipmentVisibility === 'PRIVATE') {
      return 'Notify only select partners or groups';
    }
    return 'Notify only select partners';
  };

  const shipperSubscriptionTierWithoutPrivateShipments = subscription.subscription_plan === 'SHIPPER_FREE_TIER' || subscription.subscription_plan === 'SHIPPER_SHIP_A'
  || subscription.subscription_plan === 'SHIPPER_SHIP_B' || subscription.subscription_plan === 'SHIPPER_SHIP_C';

  const loading = (
    <Stack direction="row" justifyContent="left" mt=".5rem" mb=".5rem">
      <CircularProgress />
    </Stack>
  );
  return (
    <Stack direction="column" mb="1rem" mt="1rem">
      <Typography variant="h6">{isProject ? 'Project Visibility' : 'Shipment Visibility'}</Typography>
      { subscription?.subscription_plan !== 'SHIPPER_PAY_PER_POSTING' && (
      <FormControl>
        <RadioGroup
          value={shipmentVisibility}
          onChange={handleShipmentVisibilityChange}
        >
          <FormControlLabel value="PUBLIC" control={<Radio />} label="Public" />
          <Typography variant="body1" color="text.secondary">
            {`All relevant carriers, including your selected partners,
            will be notified and able to access this ${isProject ? 'project' : 'shipment'}.`}
          </Typography>
          <Stack direction="row" alignItems="center">
            <FormControlLabel value="PRIVATE" control={<Radio disabled={shipperSubscriptionTierWithoutPrivateShipments} />} label="Private" />
            {shipperSubscriptionTierWithoutPrivateShipments && (<ShipperUpgradeNowButton buttonSize="small" />)}
          </Stack>
          <Typography variant="body1" color="text.secondary">
            {` Only your selected partners will be notified and able to access this ${isProject ? 'project' : 'shipment'}.`}
          </Typography>
        </RadioGroup>
      </FormControl>
      )}
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6">{`Which partners do you want to notify about this ${isProject ? 'project' : 'shipment'}?`}</Typography>
      <FormControl>
        <RadioGroup
          value={networkNotification}
          onChange={handleNetworkNotificationChange}
        >
          <FormControlLabel value="ALL_NETWORK" control={<Radio />} label="Notify all of my partners" />
          <FormControlLabel value="SELECTED_CARRIERS" control={<Radio />} label={getSelectPartnersLabel()} />
        </RadioGroup>
      </FormControl>
      {
        networkNotification === 'SELECTED_CARRIERS' && (loadingNetworkMembers || loadingActiveExternalInvites) && (
          loading
        )
      }
      {
        shipmentVisibility === 'PRIVATE' && networkNotification === 'SELECTED_CARRIERS' && confirmedNetworkMembers.length > 0 && (
          <PrivateShipmentPartnerSelect
            selectedCarriers={selectedCarriers}
            handleChange={handleChange}
            confirmedNetworkMembers={confirmedNetworkMembers}
            groupSelectComponent={groupSelectComponent}
          />
        )
      }
      {
        shipmentVisibility === 'PUBLIC' && networkNotification === 'SELECTED_CARRIERS' && networkMembers.length > 0 && (
          <Stack>
            <FormControl>
              <InputLabel>Partners</InputLabel>
              <Select
                multiple
                value={selectedCarriers}
                onChange={handleChange}
                input={<OutlinedInput label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {
                networkMembers.map((member: NetworkMember) => (
                  <MenuItem
                    key={member.email}
                    value={member.email}
                  >
                    {`${member.firstName ?? ''} ${member.lastName ? `${member.lastName} -` : ''} (${member.email})`}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Stack>
        )
      }
      {
        networkNotification === 'SELECTED_CARRIERS' && networkMembers.length === 0 && (
          <p>Your carrier network is empty. Invite carriers to your network today!</p>
        )
      }
      {
        networkNotification === 'SELECTED_CARRIERS' && activeExternalFetchError != null && (
          <Typography color="red" align="center">{activeExternalFetchError}</Typography>
        )
      }
      {
        networkNotification === 'SELECTED_CARRIERS' && carrierNeworkFetchError != null && (
          <Typography color="red" align="center">{carrierNeworkFetchError}</Typography>
        )
      }
    </Stack>
  );
}
