import {
  Stack, Typography,
} from '@mui/material';
import { useRoleContext } from 'hooks';

interface Props {
  children: React.ReactNode;
}
export function AdminPrivilegeFiltersContainer(props: Props) {
  const { children } = props;
  const {
    hasBranchAdminRole,
    hasCompanyAdminRole,
  } = useRoleContext();

  if (!hasBranchAdminRole() && !hasCompanyAdminRole()) {
    return <div />;
  }
  return (
    <Stack
      direction="column"
      spacing={3}
      sx={{
        borderColor: '#cecdcd',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 2,
      }}
      pl=".5rem"
      pr=".5rem"
      pb=".7rem"
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ height: '.1rem' }}
      >
        <Typography variant="body2" color="text.secondary">Admin Filters</Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >

        {children}
      </Stack>
    </Stack>
  );
}
