import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  IconButton, Stack, Tooltip,
} from '@mui/material';

import { ShipmentListContainer } from 'components/Shipper/components/ShipmentListContainer';
import { ShipmentTemplateAndCreateShipmentFormDashboardItem } from 'components/Shipper/components/ShipmentTemplateAndCreateShipmentFormDashboardItem';
import { useShipments } from 'hooks';
import { DashboardItemLayout, PageLayout } from 'layouts';
import { useEffect, useRef, useState } from 'react';
import { SHIPMENTS_PAGE_SIZE } from 'utils';

const MIN_LEFT_WIDTH = 40;
const MAX_LEFT_WIDTH = 55;

const MIN_RIGHT_WIDTH = 45;
const MAX_RIGHT_WIDTH = 60;

const DEFAULT_LEFT_WIDTH = 55;

interface Props {
  page: number;
  setPage: (page: number) => void;
}

export function HorizontalDashboard(props: Props) {
  const { page, setPage } = props;
  const [isHovered, setIsHovered] = useState(false);
  const {
    shipments, error, isLoading, count, refreshShipments,
  } = useShipments({ page, pageSize: SHIPMENTS_PAGE_SIZE });
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const leftSideRef = useRef<HTMLDivElement | null>(null);
  const rightSideRef = useRef<HTMLDivElement | null>(null);

  const [leftExpanded, setLeftExpanded] = useState(true);

  const setLeftWidth = (width: number) => {
    if (leftSideRef.current) {
      leftSideRef.current.style.width = `${width}%`;
    }
  };

  useEffect(() => {
    const resizer = resizerRef.current;
    const leftSide = leftSideRef.current;
    const rightSide = rightSideRef.current;

    if (!resizer || !leftSide || !rightSide) return;

    let x = 0;
    let leftWidth = 0;

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - x;
      // @ts-ignore
      const newLeftWidth = ((leftWidth + dx) * 100)
      // @ts-ignore
        / resizer.parentNode!.getBoundingClientRect().width;

      leftSide.style.width = `${newLeftWidth}%`;

      resizer.style.cursor = 'col-resize';
      document.body.style.cursor = 'col-resize';

      leftSide.style.userSelect = 'none';
      leftSide.style.pointerEvents = 'none';

      rightSide.style.userSelect = 'none';
      rightSide.style.pointerEvents = 'none';
    };

    const mouseUpHandler = () => {
      resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      leftSide.style.removeProperty('user-select');
      leftSide.style.removeProperty('pointer-events');

      rightSide.style.removeProperty('user-select');
      rightSide.style.removeProperty('pointer-events');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      if (isHovered) {
        setIsHovered(false);
      }
    };

    const mouseDownHandler = (e: MouseEvent) => {
      x = e.clientX;
      leftWidth = leftSide.getBoundingClientRect().width;

      document.addEventListener('mousemove', mouseMoveHandler);

      document.addEventListener('mouseup', mouseUpHandler);
    };

    resizer.addEventListener('mousedown', mouseDownHandler);

    // return function (): void {
    //   resizer.removeEventListener('mousedown', mouseDownHandler);
    //   document.removeEventListener('mousemove', mouseMoveHandler);
    //   document.removeEventListener('mouseup', mouseUpHandler);
    // };
  }, [isHovered]);

  return (
    <PageLayout>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          ref={leftSideRef}
          style={{
            width: `${DEFAULT_LEFT_WIDTH}%`,
            display: 'flex',
            minWidth: `${MIN_LEFT_WIDTH}%`,
            maxWidth: `${MAX_LEFT_WIDTH}%`,
            marginRight: '.1rem',
          }}
        >
          <DashboardItemLayout
            style={{
              p: '1rem',
            }}
          >
            <ShipmentTemplateAndCreateShipmentFormDashboardItem
              refreshShipments={refreshShipments}

            />
          </DashboardItemLayout>

        </div>
        <div
          ref={resizerRef}
          style={{
            cursor: isHovered ? 'col-resize' : 'default',
            width: '1rem',
          }}
          onMouseEnter={() => setIsHovered(true)}
          id="dragMe"
        />
        <div
          ref={rightSideRef}
          style={{
            flex: 1,
            display: 'flex',
            minWidth: `${MIN_RIGHT_WIDTH}%`,
            maxWidth: `${MAX_RIGHT_WIDTH}%`,
          }}
          id="rightSide"
        >
          <DashboardItemLayout
            style={{
              p: '1rem',
              width: '100%',
            }}
          >

            <ShipmentListContainer
              page={page}
              setPage={setPage}
              shipments={shipments}
              shipmentsError={error}
              shipmentsLoading={isLoading}
              shipmentCount={count}
              layoutAdjustments={(
                <Stack direction="row" justifyContent="start" sx={{ height: '.2rem' }}>
                  {leftExpanded ? (
                    <Tooltip title="Click to expand section">
                      <IconButton onClick={() => { setLeftWidth(MIN_LEFT_WIDTH); setLeftExpanded(false); }} size="small">
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>
                  )
                    : (
                      <Tooltip title="Click to restore section">
                        <IconButton onClick={() => { setLeftWidth(DEFAULT_LEFT_WIDTH); setLeftExpanded(true); }} size="small">
                          <ChevronRightIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </Stack>
              )}
            />
          </DashboardItemLayout>
        </div>
      </div>
    </PageLayout>
  );
}
