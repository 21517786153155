import { useCompanyBranches } from 'hooks';
import { MultiSelect } from '../Select';

interface Props {
  onChange: (value: string[]) => void;
  value: string[] | undefined;
  selectedBranchIds: string[];
}

export function BranchAdminShipperMultiSelect(props: Props) {
  const { onChange, value, selectedBranchIds } = props;
  const { branches, loading } = useCompanyBranches();

  const buildOptions = () => {
    if (loading || branches.length === 0 || selectedBranchIds.length === 0) {
      return [];
    }
    const selectedBranches = selectedBranchIds.map(
      (branchId: string) => branches.find((branch) => branch.branch.id.toString() === branchId),
    ).filter((branch) => branch !== undefined) as BranchMembers[];

    const allBranchShippers = selectedBranches.flatMap(
      (branch: BranchMembers) => branch.members,
    ).filter((members) => members.type === 'SHIPPER');

    const options = allBranchShippers.map((shipper) => ({
      value: shipper.id.toString(),
      label: `${shipper.user.first_name} ${shipper.user.last_name}`,
    }));

    return options;
  };

  const getInputLabel = () => {
    if (loading) {
      return 'Loading Shippers...';
    }
    if (selectedBranchIds.length === 0) {
      return 'Select a Branch first...';
    }
    return 'Shippers';
  };

  const getDisabledStatus = () => loading || selectedBranchIds.length === 0;

  return (
    <MultiSelect
      inputLabel={getInputLabel()}
      defaultValue={value || []}
      value={value}
      onChange={onChange}
      options={buildOptions()}
      disabled={getDisabledStatus()}
    />
  );
}
