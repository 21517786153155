import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context';
import './index.css';
import reportWebVitals from './reportWebVitals';

const DEV_API_URL = 'https://3xje7yxnal.execute-api.us-west-2.amazonaws.com/development-env';
const PROD_API_URL = 'https://1ba9u0gj2h.execute-api.us-west-2.amazonaws.com/prod-env';
const SENTRY_DSN = process.env.REACT_APP_FRONTEND_SENTRY_DSN;

if (SENTRY_DSN && SENTRY_DSN.trim() !== '') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(
        {
          networkDetailAllowUrls: ['.*'],
          maskAllText: false,
          blockAllMedia: false,
          block: ['sentry-block'],
        },
      ),
    ],
    tracesSampleRate: 0.4,
    tracePropagationTargets: [
      DEV_API_URL,
      PROD_API_URL,
    ],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.9,
  });
}

const defaultTheme = createTheme();
defaultTheme.shadows[1] = 'rgba(149, 157, 165, 0.2) 0px 8px 24px';

const theme = createTheme({
  ...defaultTheme,
  typography: {
    fontFamily: '"Quicksand", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
