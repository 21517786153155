import {
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useShipperNetworkGroup } from 'hooks';
import { ISOStringToShorthandDate } from 'utils';
import { AddPartnersToGroupMenu } from '../AddPartnersToGroupMenu';
import { EditableGroupName } from '../EditableGroupName';
import { NetworkGroupMemberTable } from '../NetworkGroupMemberTable';

interface Props {
  groupId: number;
}

export function NetworkGroupDetail(props: Props) {
  const { groupId } = props;
  const {
    networkGroup,
    loadingNetworkGroup,
    networkGroupError,
    refetchNetworkGroup,
  } = useShipperNetworkGroup(groupId);

  return (
    <Stack direction="column">
      <LoadingWrappedNode loading={loadingNetworkGroup}>
        <Stack
          direction="column"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {networkGroup && (
            <EditableGroupName
              group={networkGroup}
              onRefresh={refetchNetworkGroup}
            />
            )}
            <RefreshPageButton id="refresh-branches" onRefresh={refetchNetworkGroup} iconButton />
          </Stack>
          <Divider />
          <Stack mt=".5rem" direction="row" justifyContent="start" spacing={2}>
            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`${networkGroup?.members.length} ${networkGroup?.members.length === 1 ? 'member' : 'members'}`}</Typography>
            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`Created on ${networkGroup?.group.name ? ISOStringToShorthandDate(networkGroup?.group.created_at) : ''}`}</Typography>
          </Stack>
          <Stack mt=".5rem" direction="row" justifyContent="start" spacing={2}>
            {networkGroup
              && (
              <AddPartnersToGroupMenu
                group={networkGroup}
                onRefresh={refetchNetworkGroup}
              />
              )}
          </Stack>
          <NetworkGroupMemberTable
            members={networkGroup?.members ?? []}
            onRefresh={refetchNetworkGroup}
          />
        </Stack>
      </LoadingWrappedNode>
      <ErrorTypography error={networkGroupError} />
    </Stack>
  );
}
