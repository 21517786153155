import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Button, Divider, Modal, Stack, TextField,
  Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { SuccessTypography } from 'components/SuccessTypography';
import { useRoleContext, useWriterComponentState } from 'hooks';
import { useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRefreshNetworkGroups: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

export function CreateNetworkGroupModal(props: Props) {
  const { isOpen, onClose, onRefreshNetworkGroups } = props;
  const [groupName, setGroupName] = useState<string>('');
  const { role } = useRoleContext();

  const {
    loading, success, write, error, resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to update shipment custom name. Please contact us if the issue persists',
    writeConfig: {
      method: 'post',
      url: role ? `/shippers/${role.id}/network-groups` : '/',
      body: { name: groupName },
    },
    errorMessageKey: 'message',
  });

  const onCloseModal = () => {
    onClose();
    onRefreshNetworkGroups();
    setGroupName('');
    resetState();
  };

  const onSubmitGroup = () => {
    write(() => {
      setGroupName('');
      onRefreshNetworkGroups();
      onCloseModal();
      resetState();
    });
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Box sx={style}>
        <Stack justifyContent="flex-end" direction="row">
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onCloseModal}
          />
        </Stack>
        <Typography variant="h4" align="center">Create Group</Typography>
        <Divider sx={{ my: '1rem' }} />
        <Typography variant="body1" align="center">Enter the group name below. You will be able to add members once the group is created.</Typography>

        <Stack direction="column" spacing={2} justifyContent="center" mt="1rem">
          <TextField
            id="network-group-name"
            variant="outlined"
            onChange={(e) => setGroupName(e.target.value)}
            value={groupName}
          />
          <LoadingWrappedNode loading={loading}>
            <Stack justifyContent="center" alignContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitGroup}
                disabled={loading || groupName.length === 0}
                sx={{
                  pl: '2rem', pr: '2rem', pt: '.5rem', pb: '.5rem',
                }}
              >
                Submit
              </Button>
            </Stack>
          </LoadingWrappedNode>
        </Stack>
        <ErrorTypography error={error} />
        <SuccessTypography success={success} message="Group created." />
      </Box>
    </Modal>
  );
}
