import {
  Button,
  Stack,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useWriterComponentState } from 'hooks';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  member: NetworkGroupMember;
  onRefresh: VoidFunction;
}

export function NetworkGroupMemberTableRow(props: Props) {
  const { member, onRefresh } = props;

  const {
    write, error, loading,
  } = useWriterComponentState({
    errorMessage: 'Failed to delete request. Please contact us if the problem persists.',
    writeConfig: {
      method: 'delete',
      url: `/network-groups/${member.group.id}/delete-members`,
      body: {
        carriers: [member.member.id],
      },
    },
  });

  const onDeleteMember = () => {
    write(onRefresh);
  };

  return (
    <TableRow
      key={member.id}
      hover
    >
      <TableCell component="th" scope="row">
        {`${member.member.user.first_name} ${member.member.user.last_name}`}
      </TableCell>
      <TableCell>{member.member.company.name}</TableCell>
      <TableCell>
        {ISOStringToLocalReadableTimestamp(member.created_at)}
      </TableCell>
      <TableCell>
        <Stack direction="row" justifyContent="start" spacing={1}>
          <LoadingWrappedNode loading={loading}>
            <Button
              size="small"
              color="warning"
              variant="outlined"
              onClick={onDeleteMember}
            >
              Remove from group
            </Button>
            <ErrorTypography error={error} />
          </LoadingWrappedNode>
        </Stack>
      </TableCell>

    </TableRow>
  );
}
