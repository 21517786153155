import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useWriterComponentState } from 'hooks';
import { useState } from 'react';

interface Props {
  onRefresh: VoidFunction;
  group: NetworkGroup;
}

export function EditableGroupName(props: Props) {
  const { onRefresh, group } = props;
  const [editing, setEditing] = useState(false);
  const [groupName, setGroupName] = useState<string>(group.group.name);

  const {
    write: updateGroupName, error: updateGroupNameError, loading: loadingUpdateGroupName,
  } = useWriterComponentState({
    errorMessage: 'Failed to add members. Please contact us if the problem persists.',
    writeConfig: {
      method: 'patch',
      url: `/network-groups/${group.group.id}`,
      body: {
        name: groupName,
      },
    },
  });

  const onSubmitEdit = () => {
    updateGroupName(() => {
      setEditing(false);
      onRefresh();
    });
  };

  const getEditView = () => (
    <Stack direction="row" alignItems="center" spacing={2} mb=".5rem">
      <TextField
        id="group-name"
        variant="outlined"
        onChange={(e) => setGroupName(e.target.value)}
        value={groupName}
        sx={{ minWidth: '5rem' }}
      />
      <LoadingWrappedNode loading={loadingUpdateGroupName}>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={onSubmitEdit} disabled={groupName.length < 1 || groupName === group.group.name}>Save</Button>
          <Button variant="outlined" onClick={() => setEditing(false)}>Cancel</Button>
        </Stack>
      </LoadingWrappedNode>
      <ErrorTypography error={updateGroupNameError} />
    </Stack>
  );

  const getDisplayView = () => (
    <Stack direction="row" alignItems="center">
      <Typography variant="h4">{group.group?.name}</Typography>
      <Tooltip followCursor title="Edit Group Name" placement="right" TransitionComponent={Zoom}>
        <IconButton color="primary" onClick={() => setEditing(true)}>
          <EditIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </Stack>
  );

  return editing ? getEditView() : getDisplayView();
}
