import {
  Chip,
} from '@mui/material';

interface Props {
  isPrivate: boolean;
  isProject: boolean;
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info';
}

export function PrivateShipmentChip(props: Props) {
  const {
    isPrivate,
    isProject,
    size, color,
  } = props;

  if (!isPrivate) {
    return (
      <div />
    );
  }

  return (
    <Chip
      label={isProject ? 'PRIVATE' : 'PRIVATE SHIPMENT'}
      color={color}
      variant="outlined"
      size={size}
    />
  );
}

PrivateShipmentChip.defaultProps = {
  size: 'small',
  color: 'success',
};
