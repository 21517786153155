import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { CreateFollowRequest } from '../CreateFollowRequest';
import { ShipperNetworkTable } from './ShipperNetworkTable';
import { ShipperPendingRequests } from './ShipperPendingRequests';

interface Props {
  networkGroupsComponent: React.ReactNode;
}

export function ShipperSentRequestsContainer(props: Props) {
  const { networkGroupsComponent } = props;
  const { role } = useRoleContext();
  const [error, setError] = useState<string | null>(null);
  const [loadingPendingRequests, setLoadingPendingRequests] = useState<boolean>(true);
  const { api } = useAPI();

  // follower state variables
  const [followersError, setFollowersError] = useState<string | null>(null);
  const [followersLoading, setFollowersLoading] = useState<boolean>(true);
  const [followers, setFollowers] = useState<FollowingCarrier[]>([]);

  const [pendingRequests, setPendingRequests] = useState<FollowShipperRequestAndResponse[]>([]);

  useEffect(() => {
    if (loadingPendingRequests) {
      api<FollowShipperRequestAndResponse[]>('get', role ? `/shippers/${role.id}/follow_requests/sent` : '/').then((response) => {
        const pending: FollowShipperRequestAndResponse[] = [];
        for (let i = 0; i < response.data.length; i += 1) {
          const request: FollowShipperRequestAndResponse = response.data[i];
          if (request.response === null) {
            pending.push(request);
          }
        }
        setPendingRequests(pending);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch network information.');
      }).finally(() => {
        setLoadingPendingRequests(false);
      });
    }
  }, [api, role, loadingPendingRequests]);

  useEffect(() => {
    if (followersLoading) {
      api<FollowingCarrier[]>('get', role ? `/shippers/${role.id}/followers` : '/').then((response) => {
        setFollowers(response.data);
        setFollowersError(null);
      }).catch((e) => {
        setFollowersError(e?.response?.data?.message || 'Failed to fetch carrier network.');
      }).finally(() => {
        setFollowersLoading(false);
      });
    }
  }, [api, role, followersLoading]);

  const onFollowRequestsCreatedRefresh = () => {
    setFollowersLoading(true);
    setLoadingPendingRequests(true);
  };

  return (
    <>
      <CreateFollowRequest onFollowRequestsCreatedRefresh={onFollowRequestsCreatedRefresh} />
      {networkGroupsComponent}
      <Box
        flex={1}
      >
        <Stack direction="row" alignItems="center" mt="1rem">
          <Typography variant="h5">My Carriers</Typography>
          <RefreshPageButton id="refresh-branches" onRefresh={() => setFollowersLoading(true)} iconButton />
        </Stack>
        <EvotruxDivider />
        <Box
          mt="1rem"

        >
          <ShipperNetworkTable
            loading={followersLoading}
            followers={followers}
            error={followersError}
            onRefetchFollowers={() => setFollowersLoading(true)}
          />
        </Box>
      </Box>
      <Box
        flex={1}
        mt="2rem"
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">Pending Invites</Typography>
          <RefreshPageButton id="refresh-branches" onRefresh={() => setLoadingPendingRequests(true)} iconButton />
        </Stack>
        <EvotruxDivider />
        <Box mt="1rem">
          <ShipperPendingRequests
            error={error}
            loading={loadingPendingRequests}
            pendingRequests={pendingRequests}
            onRefetchFollowers={() => setLoadingPendingRequests(true)}
          />
        </Box>
      </Box>
    </>

  );
}
