import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Stack,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { MultiSelect } from 'components/Select';
import { useShipperNetwork, useWriterComponentState } from 'hooks';
import { useEffect, useState } from 'react';

interface Props {
  group: NetworkGroup;
  onRefresh: VoidFunction;
}

export function AddPartnersToGroupMenu(props: Props) {
  const { group, onRefresh } = props;
  const [addAbleCarriers, setAddAbleCarriers] = useState<FollowingCarrier[]>([]);
  const [selectedCarriersIds, setSelectedCarriersIds] = useState<number[]>([]);

  const {
    followingCarriers, loading: loadingPartners, error, refetch: refetchPartners,
  } = useShipperNetwork();

  const {
    write: addMembers, error: errorAddingMembers, loading: loadingAddingMembers,
  } = useWriterComponentState({
    errorMessage: 'Failed to add members. Please contact us if the problem persists.',
    writeConfig: {
      method: 'post',
      url: `/network-groups/${group.group.id}/members`,
      body: {
        carriers: selectedCarriersIds,
      },
    },
    errorMessageKey: 'message',
  });

  useEffect(() => {
    if (followingCarriers.length > 0) {
      const currentMemberCarrierIds = group.members.map(
        (member: NetworkGroupMember) => member.member.id,
      );
      const carriersNotInGroup = followingCarriers.filter(
        (carrier: FollowingCarrier) => !currentMemberCarrierIds.includes(carrier.id),
      );
      setAddAbleCarriers(carriersNotInGroup);
    }
  }, [followingCarriers, group.members]);

  const buildOptions = () => addAbleCarriers.map((carrier) => ({
    label: `${carrier.user.first_name} ${carrier.user.last_name && carrier.user.last_name.length > 0 ? `${carrier.user.last_name[0]}.` : ''} - ${carrier.company?.name ? carrier.company.name : ''}`,
    value: carrier.id.toString(),
  }));

  const onChange = (value: string[]) => {
    setSelectedCarriersIds(value.map((v) => parseInt(v, 10)));
  };

  const onAddMembers = () => {
    addMembers(onRefresh);
    refetchPartners();
  };

  return (
    <Stack direction="row" spacing={1}>
      <MultiSelect
        options={buildOptions()}
        defaultValue={[]}
        inputLabel={loadingPartners ? 'Loading Partner Network' : 'Partners'}
        value={selectedCarriersIds.map((id) => id.toString())}
        onChange={onChange}
        disabled={loadingPartners}
      />
      <Button
        variant="contained"
        startIcon={<AddIcon fontSize="large" />}
        disabled={selectedCarriersIds.length < 1}
        onClick={onAddMembers}
      >
        Add Members
      </Button>
      <LoadingWrappedNode loading={loadingAddingMembers}>
        <div />
      </LoadingWrappedNode>
      <ErrorTypography error={error} />
      <ErrorTypography error={errorAddingMembers} />
    </Stack>
  );
}
