import { Typography } from '@mui/material';
import { CompanyBranches } from 'components/CompanyBranches/CompanyBranches';
import { ErrorTypography } from 'components/ErrorTypography';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useFetchingComponentState, useRoleContext } from 'hooks';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';

export function CompanyBranchesPage() {
  const { company, listBranchAdminRoles: getBranchAdminRoles } = useRoleContext();
  const [branchMembers, setBranchMembers] = useState<BranchMembers[] | null>(null);
  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<BranchMembers[]>({
    errorMessage: 'Failed to fetch branches',
    fetchConfig: {
      url: company !== null ? `/companies/${company.id}/branches` : '',
    },
  });

  useEffect(() => {
    if (!success && error == null) {
      fetch((response: BranchMembers[]) => setBranchMembers(response));
    }
  }, [fetch, success, error]);

  if (company == null) {
    return <div />;
  }

  return (
    <PageLayout>
      <Typography variant="h3">Company Branches</Typography>
      <EvotruxDivider />
      <ErrorTypography error={error} />
      <LoadingWrappedNode loading={loading}>
        {branchMembers && (
          <CompanyBranches
            branches={branchMembers}
            viewingAsRoles={getBranchAdminRoles()}
            viewingAsCompanyAdmin={false}
          />
        )}
      </LoadingWrappedNode>
    </PageLayout>
  );
}
