import {
  Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { NetworkMember } from '../../types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Props {
  confirmedNetworkMembers: NetworkMember[];
  selectedCarriers: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  groupSelectComponent: React.ReactNode;
}

export function PrivateShipmentPartnerSelect(props: Props) {
  const {
    selectedCarriers,
    handleChange,
    confirmedNetworkMembers,
    groupSelectComponent,
  } = props;
  return (
    <Stack direction="column" spacing={1}>
      {groupSelectComponent}
      <FormControl>
        <InputLabel>Partners</InputLabel>
        <Select<string[]>
          multiple
          value={selectedCarriers}
          onChange={handleChange}
          input={<OutlinedInput label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          }}
        >
          {
                confirmedNetworkMembers.map((member: NetworkMember) => (
                  <MenuItem
                    key={member.email}
                    value={member.email}
                  >
                    {`${member.firstName ?? ''} ${member.lastName ? `${member.lastName} -` : ''} (${member.email})`}
                  </MenuItem>
                ))
                }
        </Select>
      </FormControl>
    </Stack>
  );
}
