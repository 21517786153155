import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useShipperNetworkGroups } from 'hooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Props {
  selectedGroups: string[];
  handleChange: (value: string[]) => void;
}

export function PrivateShipmentGroupSelect(props: Props) {
  const { selectedGroups, handleChange } = props;
  const {
    networkGroups,
    loadingNetworkGroups,
    networkGroupsError,
    networkGroupsFetchSuccess,
  } = useShipperNetworkGroups();

  const getPlaceHolder = () => {
    let placeholderLabel = 'Select a group...';
    if (loadingNetworkGroups) {
      placeholderLabel = 'Loading Groups...';
    } else if (networkGroupsError) {
      placeholderLabel = 'Error loading groups. Please contact us if the issue persists.';
    } else if (networkGroupsFetchSuccess && networkGroups.length === 0 && !loadingNetworkGroups) {
      placeholderLabel = 'You have no groups. Create and manage groups in the network page!';
    }
    return (
      <MenuItem disabled value="">
        <em>{placeholderLabel}</em>
      </MenuItem>
    );
  };

  const onChange = (event: SelectChangeEvent<typeof selectedGroups>) => {
    const {
      target: { value },
    } = event;
    handleChange(typeof value === 'string' ? value.split(',') : value);
  };

  const getGroupLabel = (groupId: string) => {
    const group = networkGroups.find((g) => g.group.id === parseInt(groupId, 10));
    return group?.group?.name ?? '';
  };

  return (
    <FormControl>
      <InputLabel>Groups</InputLabel>
      <Select<string[]>
        multiple
        value={selectedGroups}
        onChange={onChange}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={getGroupLabel(value)} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        }}
      >
        {getPlaceHolder()}
        {
            networkGroups.map((group: NetworkGroup) => (
              <MenuItem
                key={group.group.id}
                value={group.group.id}
              >
                {`${group.group?.name ?? ''} (${group.members.length} partners)`}
              </MenuItem>
            ))
            }
      </Select>
    </FormControl>
  );
}
