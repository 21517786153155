import {
  Box,
  CircularProgress, Paper, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { EstablishedNetworkTableRow } from './EstablishedNetworkTableRow';

interface Props{
  error: string | null;
  loading: boolean;
  followed: FollowedShipper[];
  onUnfollowShipperClicked: (shipper: FollowedShipper) => void;
}

export function EstablishedNetwork(props: Props) {
  const {
    error, loading, followed, onUnfollowShipperClicked,
  } = props;

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error) {
    return (
      <Stack>
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }
  if (followed.length === 0) {
    return (
      <Stack>
        <Typography variant="h6">You are not currently following anyone.</Typography>
      </Stack>
    );
  }
  return (
    <Box
      sx={{
        maxHeight: '500px', overflowY: 'scroll',
      }}
    >
      <TableContainer
        component={Paper}

      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { followed.length > 0 ? followed.map((f) => (
              <EstablishedNetworkTableRow
                key={f.id}
                followed={f}
                onUnfollowShipperClicked={onUnfollowShipperClicked}
              />
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
