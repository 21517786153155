import {
  Box, CircularProgress, Container, Stack,
} from '@mui/material';
import { CarrierEssentialsUpgradeMenu } from 'components/Carrier/components/CarrierEssentialsUpgradeMenu';
import { ChatPage } from 'components/ChatPage';
import { CompanyBranchesPage } from 'components/CompanyBranchesPage';
import { CompanyDetails } from 'components/CompanyDetails';
import { CompanyProfilePage } from 'components/CompanyProfilePage';
import { CustomerIOWrapper } from 'components/CustomerIOWrapper';
import { Network } from 'components/Network';
import { DataPageWrapper } from 'components/Shipper/components/DataPageWrapper';
import { ShipperUpgradePage } from 'components/Shipper/components/ShipperUpgradePage';
import { TawkToWrapper } from 'components/TawkToWrapper';
import { UserAccountPage } from 'components/UserAccountPage';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import {
  Route, Routes,
} from 'react-router-dom';
import { Authentication } from './components/Authentication';
import { CompanySetup } from './components/CompanySetup';
import { ContentSwitcher } from './components/ContentSwitcher';
import { Shipment } from './components/Shipment';
import { CARRIER_UPGRADE_RELATIVE_URL, SHIPPER_UPGRADE_RELATIVE_URL } from './constants';
import { RoleProvider, SubscriptionProvider } from './context';
import { useAuthContext, useLoadPath } from './hooks';
import { AppLayout } from './layouts';

function App() {
  const {
    isAuthenticated, user, isLoading, challenge, pubnub: pubnubUserInfo,
  } = useAuthContext();
  useLoadPath();

  const showLogin = !isAuthenticated || user == null;
  const showForceChangePassword = challenge
    && challenge.ChallengeName === 'NEW_PASSWORD_REQUIRED';

  const pubnub = new PubNub({
    publishKey: pubnubUserInfo?.publish_key ?? 'unknown',
    subscribeKey: pubnubUserInfo?.subscribe_key ?? 'unknown',
    userId: pubnubUserInfo?.user_uuid ?? 'unknown',
    authKey: pubnubUserInfo?.token ?? 'unknown',
  });

  if (isLoading) {
    return (
      <Container sx={{ height: '100vh' }}>
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  function getPage() {
    return (<Authentication />);
  }

  return (
    <Box>
      {showLogin || showForceChangePassword ? (
        getPage()
      ) : (
        <RoleProvider>
          <SubscriptionProvider>
            <PubNubProvider client={pubnub}>
              <AppLayout content={(
                <>
                  <CustomerIOWrapper />
                  <TawkToWrapper />
                  <Routes>
                    <Route path="/" element={<ContentSwitcher />} />
                    <Route path="/chat" element={<ChatPage />} />
                    <Route path="/chat/:id" element={<ChatPage />} />
                    <Route path="/shipments/:id" element={<Shipment />} />
                    <Route path="/setup-company" element={<CompanySetup />} />
                    <Route path="/companies/:id" element={<CompanyDetails />} />
                    <Route path="/companies/:id/profile" element={<CompanyProfilePage />} />
                    <Route path="/companies/:id/branches" element={<CompanyBranchesPage />} />
                    <Route path="/account" element={<UserAccountPage />} />
                    <Route path="/network" element={<Network />} />
                    <Route
                      path={CARRIER_UPGRADE_RELATIVE_URL}
                      element={<CarrierEssentialsUpgradeMenu />}
                    />
                    <Route
                      path={SHIPPER_UPGRADE_RELATIVE_URL}
                      element={<ShipperUpgradePage />}
                    />
                    <Route path="/shippers/data" element={<DataPageWrapper />} />
                  </Routes>
                </>
            )}
              />
            </PubNubProvider>
          </SubscriptionProvider>
        </RoleProvider>
      )}
    </Box>
  );
}

export default App;
