import { Card, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ISOStringToShorthandDate } from 'utils';

interface Props {
  group: NetworkGroup;
  onManageGroup: (group: NetworkGroup) => void;
}

export function NetworkGroupOverview(props: Props) {
  const { group, onManageGroup } = props;
  const { group: groupDetail, members } = group;
  return (
    <Card sx={{ pb: '.5rem' }}>
      <CardContent>
        <Typography variant="h5">{groupDetail.name}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`${members.length} ${members.length === 1 ? 'member' : 'members'}`}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`Created on ${ISOStringToShorthandDate(groupDetail.created_at)}`}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => onManageGroup(group)} variant="outlined">Manage</Button>
      </CardActions>
    </Card>

  );
}
