import {
  Dialog,
} from '@mui/material';
import { Box } from '@mui/system';
import { NetworkGroupDetail } from '../NetworkGroupDetail/NetworkGroupDetail';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRefreshNetworkGroups: () => void;
  group: NetworkGroup | null;
}

export function NetworkGroupDetailModal(props: Props) {
  const {
    isOpen, onClose, onRefreshNetworkGroups, group,
  } = props;

  const onCloseModal = () => {
    onClose();
    onRefreshNetworkGroups();
  };

  return (
    <Dialog
      open={isOpen && group !== null}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          width: '70vw', // 50% of the viewport width
          height: '70vh', // 50% of the viewport height
          maxWidth: 'none', // Ensures it doesn't shrink based on default max-width
          overflowY: 'scroll',
        },
      }}
    >
      <Box p="1.5rem">
        {group && <NetworkGroupDetail groupId={group.group.id} />}
      </Box>
    </Dialog>
  );
}
