import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

export function useShipperNetworkGroups() {
  const { role } = useRoleContext();
  const [networkGroups, setNetworkGroups] = useState<NetworkGroup[]>([]);
  const {
    fetch, loading, error, success, resetState,
  } = useFetchingComponentState<NetworkGroup[]>({
    errorMessage: 'Failed to fetch network groups',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/network-groups` : '/',
    },
  });

  useEffect(() => {
    if (role != null && role.type === 'SHIPPER' && !success) {
      fetch((res) => {
        setNetworkGroups(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    networkGroups,
    loadingNetworkGroups: loading,
    networkGroupsError: error,
    refetchNetworkGroups: resetState,
    networkGroupsFetchSuccess: success,
  };
}
